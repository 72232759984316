/*
 * @Author: xieliangkai@lf-network.com xieliangkai@lf-network.com
 * @Date: 2024-05-11 15:03:10
 * @LastEditors: xieliangkai@lf-network.com xieliangkai@lf-network.com
 * @LastEditTime: 2024-05-29 13:57:01
 * @FilePath: /teamoa-frontend/src/utils/apiFormat.ts
 * @Description: 格式化请求入参与出参工具
 */
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

/**
 *
 * @description 过滤tablePro需要的数据格式
 * @param {*} response
 * @return {*}
 */
function tableProResponseFormat(response: any) {
  const { data = {} } = response;
  const responseFormat = {
    current: data.currentPage,
    data: data.lists,
    pageSize: data.per_page,
    total: data.total,
    success: true,
  };
  return responseFormat;
}

// 清除空字符串、null和undefined
export const clearFalseValue = (compactParam: any) => {
  const _compactParam = cloneDeep(compactParam);
  for (const key in _compactParam) {
    if (
      isNil(_compactParam[key]) ||
      _compactParam[key] === '' ||
      (Array.isArray(_compactParam[key]) && _compactParam[key].length === 0)
    ) {
      delete _compactParam[key];
    }
  }
  return _compactParam;
};

// 格式化page和pageSize
export const getTableProPostParams = (params: Record<string, any>) => {
  const filter = {} as any;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      switch (key) {
        case 'current':
          filter.page = params[key];
          break;
        case 'pageSize':
          filter.per_page = params[key];
          break;
        default:
          filter[key] = params[key];
          break;
      }
    }
  }
  return filter;
};

// 格式化为filter
export const getTableProGetParams = (params: Record<string, any>) => {
  const filter = {} as any;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      switch (key) {
        case 'current':
          filter.page = params[key];
          break;
        case 'pageSize':
          filter.per_page = params[key];
          break;
        case 'sort':
          if (Object.keys(params.sort).length === 0) {
            break;
          }
          // eslint-disable-next-line no-case-declarations
          const newSort: any = { key: '', value: '' };
          // eslint-disable-next-line guard-for-in
          for (let key2 in params.sort) {
            newSort.key = key2;
            if (params.sort[key2] === 'descend') {
              newSort.value = 'desc';
            } else if (params.sort[key2] === 'ascend') {
              newSort.value = 'asc';
            }
          }
          filter.sorted = newSort;
          break;
        case 'current_event_type':
          filter[`filter[current_event_type]`] = params[key].join(',');
          break;
        default:
          filter[`filter[${key}]`] = params[key];
          break;
      }
    }
  }
  return filter;
};
// 格式化为filter- post
export const getTableProPostParams_new = (params: Record<string, any>) => {
  const newParams = {} as any;
  const filter = {} as any;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      switch (key) {
        case 'current':
          newParams.page = params[key];
          break;
        case 'pageSize':
          newParams.per_page = params[key];
          break;
        case 'sort':
          if (Object.keys(params.sort).length === 0) {
            break;
          }
          // eslint-disable-next-line no-case-declarations
          const newSort: any = { key: '', value: '' };
          // eslint-disable-next-line guard-for-in
          for (let key2 in params.sort) {
            newSort.key = key2;
            if (params.sort[key2] === 'descend') {
              newSort.value = 'desc';
            } else if (params.sort[key2] === 'ascend') {
              newSort.value = 'asc';
            }
          }
          newParams.sorted = newSort;
          break;
        case 'current_event_type':
          filter.current_event_type = params[key].join(',');
          break;
        default:
          filter[key] = params[key];
          break;
      }
    }
  }
  newParams.filter = filter;
  return newParams;
};

export default {
  tableProResponseFormat,
  clearFalseValue,
  getTableProPostParams,
  getTableProGetParams,
  getTableProPostParams_new,
};
