// 阿里前端实时监控
import BrowerLogger from 'alife-logger';

let aliLogger = window.aliLogger;
const isProduction = window.ENVIRONMENT_TYPE === 'production';
const isLocal = !window.ENVIRONMENT_TYPE;

const EnvironmentMap = {
  production: {
    sample: 20,
    pvSample: 20,
    environment: 'prod',
  },
  staging: {
    sample: 20,
    pvSample: 20,
    environment: 'pre',
  },
  test: {
    sample: 20,
    pvSample: 20,
    environment: 'daily',
  },
  develop: {
    sample: 20,
    pvSample: 20,
    environment: 'local',
  },
};

const { sample, pvSample, environment } =
  EnvironmentMap[`${window.ENVIRONMENT_TYPE}`] || EnvironmentMap['develop'];

if (!aliLogger) {
  try {
    // https://www.alibabacloud.com/help/zh/arms/browser-monitoring/developer-reference/sdk-reference#sc-instruction
    aliLogger = BrowerLogger.singleton({
      pid: 'jd07jw68wc@79316d890e538ef',
      appType: 'web',
      imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
      sendResource: true,
      enableLinkTrace: true,
      behavior: true,
      disableHook: true,
      enableSPA: false,
      useFmp: true,
      disabled: isLocal, // 一般情况下：本地环境不采集任何数据
      sample,
      pvSample,
      environment,
      ignore: {
        ignoreErrors: [
          /ResizeObserver loop limit exceeded/,
          /ResizeObserver loop completed with undelivered notifications/,
        ],
      },
    });

    if (aliLogger) {
      aliLogger.pipe = ['setPage', location?.pathname];
    }
  } catch (error) {}
}

interface ReportAPIParams {
  url: string; // 接口名
  success: boolean; // 是否调用成功
  time: number; // 接口耗时
  code?: string | number; // 返回码
  message?: string; // 返回信息
  begin?: number; // API请求开始时间戳
  traceId?: string; // EagleEye-TraceID的值
  sid?: string; // EagleEye-SessionID的值
}

export const reportAPI = ({
  url,
  success,
  time,
  code,
  message,
  begin,
  traceId,
  sid,
}: ReportAPIParams) => {
  if (aliLogger) {
    aliLogger.api(url, success, time, code, message, begin, traceId, sid);
  }
  if (!isProduction) {
    console.log('[REPORT_API]', [url, success, time, code, message, begin, traceId, sid]);
  }
};

export default aliLogger;
